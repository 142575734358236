import { AxiosResponse } from 'axios';
import { add } from 'date-fns';
import { IAccount } from '../interfaces/IAccount';
import { IAccountFinancialHistory } from '../interfaces/IAccountFinancialHistory';
import { IAccountInformation } from '../interfaces/IAccountInformation';
import IFinancialCapital from '../interfaces/IFinancialCapital';
import { IInvestOrder, InvestOrderSubType } from '../interfaces/IInvestOrder';
import { IInvestProfileCategories } from '../interfaces/IInvestProfileCategory';
import { IInvestRequest, INVEST_REQUEST_TYPES, InvestRequestFrequency, InvestRequestType } from '../interfaces/IInvestRequest';
import IIriArray from '../interfaces/IIriArray';
import IManagementMode from '../interfaces/IManagementMode';
import { IOrder, IOrders, ORDER_STATUS_MAP, OrderStatus } from '../interfaces/IOrder';
import IProviderContractType from '../interfaces/IProviderContractType';
import { IUserInvestmentAccountNeed } from '../interfaces/IUserInvestmentAccountNeed';
import { IUserInvestmentAccountProduct, IUserInvestmentAccountProducts } from '../interfaces/IUserInvestmentAccountProduct';
import { IRIString } from '../interfaces/IriType';
import HttpQueueService from './HttpQueueService';
import ServiceConstructor from './ServiceConstructor';

class AccountsService extends ServiceConstructor {
  public getUserInvestmentAccountInformationCollection(params?: URLSearchParams): Promise<AxiosResponse<IIriArray<IAccountInformation>>> {
    return this.getRessource<IIriArray<IAccountInformation>>('/v1/user_investment_account_informations', params);
  }

  public getUserInvestmentAccountInformation(id: IAccountInformation['id']): Promise<AxiosResponse<IAccountInformation>> {
    return this.getRessource<IAccountInformation>(`/v1/user_investment_account_informations/${ id }`);
  }

  public getAccount(accountIri: IRIString): Promise<AxiosResponse<IAccount>> {
    return this.getRessource<IAccount>(accountIri);
  }

  public getUserFinancialCapital(accountIri: IRIString): Promise<AxiosResponse<IFinancialCapital>> {
    return this.getRessource<IFinancialCapital>(`${ accountIri }/user_financial_capital`);
  }

  public getAccountInvestmentValues(account: IAccount): Promise<AxiosResponse<IAccountFinancialHistory>> {
    return this.getRessource<IAccountFinancialHistory>(`${ account['@id'] }/financial_history`);
  }

  public async getAccountInvestmentProducts(accountIri: IAccount['@id'], investProfileCategories: IInvestProfileCategories): Promise<IUserInvestmentAccountProducts | undefined> {
    try {
      const response = await this.getRessource<IIriArray<IUserInvestmentAccountProduct>>(`${ accountIri }/user_investment_account_products`);

      return response.data['hydra:member'].map((userInvestmentAccountProduct) => {
        userInvestmentAccountProduct.investProfileCategoriesCustom = userInvestmentAccountProduct.investProfileCategories.map(
          (productProfileCategory) => investProfileCategories.find(
            (investProfileCategory) => investProfileCategory['@id'] === productProfileCategory,
          ),
        ).filter((obj) => obj) as IInvestProfileCategories;
        return userInvestmentAccountProduct;
      });
    } catch (e) {
      console.debug(e);
      throw new Error('Failed');
    }
  }

  public async getInvestRequests(accountIri: IRIString): Promise<IOrders | undefined> {
    const params = {
      page: '1',
      limit: '200',
    };

    try {
      const response = await HttpQueueService.makeSingleRequest<IIriArray<IInvestRequest>>({
        method: 'GET',
        url: `${ accountIri }/invest_requests`,
        signal: this.signal,
        params,
      });

      return response.data['hydra:member'].map((investRequest) => {
        return {
          ...investRequest,
          type: INVEST_REQUEST_TYPES[investRequest?.type as InvestRequestType] as IOrder['type'],
          monthlyInvestment: investRequest.frequency === InvestRequestFrequency.FREQUENCY_PERIODIC,
          status: ORDER_STATUS_MAP[investRequest?.status],
          orderDetails: investRequest.investRequestDetails,
        };
      }).filter(
        (order) => [
          OrderStatus.STATUS_ACCEPTED,
          OrderStatus.STATUS_CREATION,
          OrderStatus.STATUS_PENDING,
          OrderStatus.STATUS_ON_HOLD,
          OrderStatus.STATUS_FAILED,
        ].includes(order.status) || InvestRequestType.TYPE_STOP_MONTHLY_INVESTMENT === order.type,
      );
    } catch (e) {
      console.debug(e);
      throw new Error('Failed');
    }
  }

  public async getInvestOrders(accountIri: IRIString): Promise<IOrders | undefined> {
    const params = {
      page: '1',
      limit: '200',
    };

    try {
      const response = await HttpQueueService.makeSingleRequest<IIriArray<IInvestOrder>>({
        method: 'GET',
        url: `${ accountIri }/invest_orders`,
        signal: this.signal,
        params,
      });

      return response.data['hydra:member'].map((investOrder) => {
        return {
          ...investOrder,
          type: investOrder.type as IOrder['type'],
          monthlyInvestment: investOrder.subType === InvestOrderSubType.SUBTYPE_MONTHLY_INVESTMENT,
          error: [OrderStatus.STATUS_REFUSED, OrderStatus.STATUS_FAILED].includes(investOrder.status),
          status: ORDER_STATUS_MAP[investOrder?.status],
          orderDetails: investOrder.investOrderDetails,
        };
      }).filter((order) => {
        if (order.subType && [InvestOrderSubType.SUBTYPE_FEES, InvestOrderSubType.SUBTYPE_REFUND].includes(order.subType)) {
          return false;
        }

        return [OrderStatus.STATUS_COMPLETED, OrderStatus.STATUS_ACCEPTED].includes(order.status);
      });
    } catch (e) {
      console.debug(e);
      throw new Error('Failed');
    }
  }

  public getProviderContractType(iri: IRIString): Promise<AxiosResponse<IProviderContractType>> {
    return HttpQueueService.makeSingleRequest<IProviderContractType>({
      method: 'GET',
      url: iri,
      signal: this.signal,
    });
  }

  public getProviderContractTypes(): Promise<AxiosResponse<IIriArray<IProviderContractType>>> {
    return HttpQueueService.makeSingleRequest<IIriArray<IProviderContractType>>({
      method: 'GET',
      url: '/v1/provider_contract_types',
      signal: this.signal,
    });
  }

  public getAccountNeeds(accountIri: IAccount['@id']): Promise<AxiosResponse<IIriArray<IUserInvestmentAccountNeed>>> {
    const params = {
      'order[createdAt]': 'desc',
    };

    return HttpQueueService.makeSingleRequest<IIriArray<IUserInvestmentAccountNeed>>({
      method: 'GET',
      url: `${ accountIri }/user_investment_account_needs`,
      signal: this.signal,
      params,
    });
  }

  public getRetractionDate(account: IAccount): Date | null {
    return account.effectiveDate ? add(new Date(account.effectiveDate), { days: 30 }) : null;
  }

  public getManagementMode(managementModeIri: IRIString): Promise<AxiosResponse<IManagementMode>> {
    return HttpQueueService.makeSingleRequest<IManagementMode>({
      method: 'GET',
      url: managementModeIri,
      signal: this.signal,
    });
  }
}

export default AccountsService;
